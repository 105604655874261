import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69')
];

export const server_loads = [0,2,6,7,3,5,18,19,10,13,14,15];

export const dictionary = {
		"/(workspace)": [~20,[2]],
		"/(workspace)/admin": [~26,[2,6]],
		"/admin/billing/canceled": [51],
		"/admin/billing/success": [52],
		"/(workspace)/admin/users": [27,[2,6,7]],
		"/(workspace)/admin/users/invite": [~29,[2,6,7]],
		"/(workspace)/admin/users/[id]": [~28,[2,6,7]],
		"/auth/(basic)/confirm-signup": [~53,[16]],
		"/auth/(basic)/forgot-password": [~54,[16]],
		"/auth/(basic)/forgot-password/success": [55,[16]],
		"/auth/lance/signup": [~61],
		"/auth/(basic)/login": [~56,[16]],
		"/auth/(basic)/old-unused-signup-flow": [~57,[16]],
		"/auth/(basic)/old-unused-signup-flow/success": [58,[16]],
		"/auth/(procedure)/signup": [~60],
		"/auth/(basic)/update-password": [~59,[16]],
		"/(workspace)/bot-builder": [30,[2]],
		"/(workspace)/builder": [~31,[2,8]],
		"/(workspace)/builder/create": [33,[2,8]],
		"/(workspace)/builder/[workflow_id]": [~32,[2,8]],
		"/(workspace)/(app)/chat": [21,[2,3],[,4]],
		"/(workspace)/(app)/chat/create": [~25,[2,3],[,4]],
		"/(workspace)/(app)/chat/[id]": [22,[2,3,5],[,4]],
		"/(workspace)/(app)/chat/[id]/data": [~23,[2,3,5],[,4]],
		"/(workspace)/(app)/chat/[id]/edit": [~24,[2,3,5],[,4]],
		"/image-library": [62],
		"/(workspace)/integrations": [~34,[2,9]],
		"/(workspace)/integrations/github": [~35,[2,9]],
		"/(workspace)/integrations/googledrive": [~36,[2,9]],
		"/knowledge-builder": [63,[18]],
		"/knowledge-builder/repository": [64,[18]],
		"/knowledge-builder/web": [65,[18]],
		"/maintenance": [66],
		"/organizations": [67,[19]],
		"/(workspace)/super": [~37,[2,10]],
		"/super/email": [~68],
		"/(workspace)/super/organizations": [~38,[2,10]],
		"/(workspace)/super/organizations/create": [40,[2,10]],
		"/(workspace)/super/organizations/[id]": [~39,[2,10]],
		"/(workspace)/super/widget-conversation/[id]": [~41,[2,10]],
		"/support": [69],
		"/(workspace)/user-profile": [~42,[2]],
		"/(workspace)/warehouse": [~43,[2,11]],
		"/(workspace)/warehouse/[botId]": [~44,[2,11]],
		"/(workspace)/whats-new": [45,[2]],
		"/(workspace)/widgets": [~46,[2,12]],
		"/(workspace)/widgets/create": [~50,[2,12]],
		"/(workspace)/widgets/[widget_id]": [~47,[2,12,13]],
		"/(workspace)/widgets/[widget_id]/conversations": [48,[2,12,13,14]],
		"/(workspace)/widgets/[widget_id]/conversations/[conversation_id]": [~49,[2,12,13,14,15]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';